import { jakarta } from "@/helpers/font"

const Portal = (props: { children: React.ReactNode }) => {
  return (
    <div className={`${jakarta.variable} font-sans`}>
      {props.children}
    </div>
  )
}

export default Portal